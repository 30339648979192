import Image from 'next/image'
import clsx from 'clsx'
import { ImageJsonLd } from 'next-seo'

import { Icon, PlainText, PrismicLink } from 'ui'
import { toCustomerLabsProperty, useAnalyticalDataContext, useCustomerLabsContext } from 'analytics'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import type { GlobalDocument, PageDocumentDataBodyMarketingCardsSlice } from 'types/types.generated'

type Props = {
	marketingData: PageDocumentDataBodyMarketingCardsSlice
	globalData: GlobalDocument
}

export default function MarketingCard({ marketingData, globalData }: Props) {
	const {
		columns,
		background_color,
		text_contrast_mode,
		background_image,
		label,
		title,
		description,
		cta_label,
		cta_link,
		content_alignment,
		tags,
		height
	} = marketingData

	const trackClick = useAnalyticsMarketingCardClickHandler(tags, title)
	let backgroundColor = background_color
	if (backgroundColor == null || backgroundColor == undefined) {
		backgroundColor = '#F5F5F1'
	}

	let colSpan
	if (columns === '1') {
		colSpan = 'col-span-4'
	} else if (columns === '1.5') {
		colSpan = 'col-span-6'
	} else if (columns === '2') {
		colSpan = 'col-span-8'
	} else if (columns === '3') {
		colSpan = 'col-span-12'
	}

	let cardHeight = 440
	if (height === 'small') {
		cardHeight = 361
	} else if (height === 'medium') {
		cardHeight = 440
	} else if (height === 'large') {
		cardHeight = 561
	}

	let sizes
	// subtracting page px and gap-x's to calculate true image width
	if (columns === '1') {
		sizes =
			'(max-width: 640px) calc(100vw - 32px), (max-width: 768px) calc(33vw - 32px - 32px), (max-width: 1024px) calc(33vw - 48px - 32px), (max-width: 1280px) calc(33vw - 80px - 32px), (max-width: 1728px) calc(33vw - 128px - 32px), 548px'
	} else if (columns === '1.5') {
		sizes =
			'(max-width: 640px) calc(100vw - 32px), (max-width: 768px) calc(50vw - 32px - 16px), (max-width: 1024px) calc(50vw - 48px - 16px), (max-width: 1280px) calc(33vw - 80px - 16px), (max-width: 1728px) calc(50vw - 128px - 16px), 830px'
	} else if (columns === '2') {
		sizes =
			'(max-width: 640px) calc(100vw - 32px), (max-width: 768px) calc(66vw - 48px - 16px), (max-width: 1024px) calc(66vw - 48px - 16px), (max-width: 1280px) calc(66vw - 80px - 16px), (max-width: 1728px) calc(66vw - 128px - 16px), 1114px'
	} else if (columns === '3') {
		sizes =
			'(max-width: 640px) calc(100vw - 32px), (max-width: 768px) calc(100vw - 48px), (max-width: 1024px) calc(100vw - 48px), (max-width: 1280px) calc(100vw - 80px), (max-width: 1728px) calc(100vw - 128px), 1600px'
	}

	return marketingData ? (
		<PrismicLink
			link={cta_link}
			label={cta_label}
			globalData={globalData}
			onClick={() => {
				trackClick()
			}}
			className={`marketing-card bg-g-100 group relative overflow-hidden rounded-md 
				h-[${cardHeight || 500}px] min-h-[350px]
				${text_contrast_mode === 'light' ? 'text-white' : 'text-black-rt'}
				${colSpan} max-sm:col-span-12
			`}
		>
			{/* Background image */}
			{background_image?.url ? (
				<>
					<Image
						src={background_image.url}
						alt={background_image.alt ?? ''}
						fill
						className="absolute w-full h-full object-cover scale-image-animation z-0"
						sizes={sizes}
						quality={70}
					/>
					<ImageJsonLd
						contentUrl={background_image.url}
						images={[background_image]}
						creator="Real Thread"
						creditText="Real Thread"
					/>
				</>
			) : null}

			{/* Text & Button */}
			<div
				className={clsx(
					'relative flex flex-col gap-3 md:gap-6 h-full px-4 py-6 md:py-12 md:px-10 z-10',
					{
						['text-white']: text_contrast_mode === 'light',
						['text-black-rt']: text_contrast_mode === 'dark',
						['justify-start']: content_alignment === 'top',
						['justify-center']: content_alignment === 'center',
						['justify-end']: content_alignment === 'bottom',
						['sm:max-w-[60%]']: columns == '1.5',
						['max-w-[260px]']: columns == '2',
						['max-w-[733px]']: columns == '3'
					}
				)}
			>
				{/* Label */}
				{label && <PlainText as="span" className="text-sm" content={label} />}

				{/* Title */}
				<PlainText as="h2" className="desktop-h2 break-keep" content={title} />

				{/* Description */}
				{description && (
					<PlainText
						className={clsx(
							'leading-6 max-w-md',
							text_contrast_mode === 'light' ? 'text-white' : 'text-g-500'
						)}
						content={description}
					/>
				)}

				{/* Link */}
				{cta_label && (
					<button
						className={`button button-md max-w-fit items-center ${
							text_contrast_mode == 'light' ? 'button-light' : 'button-dark'
						}`}
						onClick={trackClick}
					>
						<span className="-mb-1">{cta_label}</span>
						<Icon
							icon={faArrowRight}
							className="right-arrow-hover group-hover:translate-x-1 text-xs"
						/>
					</button>
				)}
			</div>
		</PrismicLink>
	) : null
}

function useAnalyticsMarketingCardClickHandler(tags: Array<any>, title: string) {
	const { trackClick } = useCustomerLabsContext()
	const { page_title, page_url } = useAnalyticalDataContext()
	return () => {
		if (tags.includes('categories')) {
			trackClick('Click', {
				customProperties: toCustomerLabsProperty({
					click_type: 'Product Category',
					click_value: title,
					page_title,
					page_url
				}).v
			})
		}
	}
}
